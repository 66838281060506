import React, { useState } from 'react';
import Login from './Components/Login';
import Dashboard from './Dashboard';
import './App.css';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;


const App: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [studentId, setStudentId] = useState('');
    const [studentName, setStudentName] = useState('');

    const handleLoginSuccess = (id: string, name: string) => {
        setStudentId(id);
        setStudentName(name);
        setIsLoggedIn(true);
        console.log('Login successful:', id, name);
        
    };

    const handleClear = async () => {
        try {
            const response = await axios.get(`${apiUrl}/clear_db`);
        } catch (error) {
            console.error('Error clearing db:', error);
        }
        window.location.href = '/'; 
        console.log('Clear successful');
        
    };

    return (
        <div className="App">
            <span className="heading">DormDropTest</span>
            
            {!isLoggedIn ? (
                <Login onLoginSuccess={handleLoginSuccess} />
            ) : (
                <div>
                    <h2>Welcome, {studentName}!</h2>
                    <Dashboard studentId={studentId} studentName={studentName} />
                    <button onClick={handleClear}>Clear Database</button>
                </div>
            )}
            
            
        </div>
    );
};

export default App;
