import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;


// Define the type for a Posting object
interface Posting {
    id: number;
    name: string;
    description: string;
    category: string;
    location: string;
    created_at: string;
    contact: string;
    quality: number; 
    poster_id: String;
}

const Dashboard: React.FC<{ studentId: string; studentName: string }> = ({ studentId, studentName }) => {
    const [postings, setPostings] = useState<Posting[]>([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [contact, setContact] = useState('');
    const [quality, setQuality] = useState<number>(1); 
    const [currentView, setCurrentView] = useState<'view' | 'create' | 'edit'>('view'); 
    const [editingPostingId, setEditingPostingId] = useState<number | null>(null); 

    // Fetch postings when the component loads and current view is 'view'
    useEffect(() => {
        if (currentView === 'view') {
            axios.get(`${apiUrl}/postings`)
            .then(response => {
                setPostings(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch postings:', error);
            });
        }
    }, [studentId, currentView]);

    // Handle view postings button
    const handleViewPostings = () => {
        setCurrentView('view'); 
        resetForm(); 
    };

    // Handle create posting button
    const handleCreatePosting = () => {
        setCurrentView('create'); 
        resetForm(); 
    };

    // Handle create posting submission
    const handleCreateSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newPosting = {
            name,
            description,
            poster_id: studentId,
            category,
            location,
            contact,
            quality, 
        };
        axios.post(`${apiUrl}/postings`, newPosting)
            .then(response => {
                console.log(response.data.message);
                setCurrentView('view'); // Go back to viewing postings after creating one
                resetForm(); // Reset form fields
            })
            .catch(error => {
                console.error('Failed to create posting:', error);
            });
    };

    // Handle edit posting button click
    const handleEditPosting = (posting: Posting) => {
        setEditingPostingId(posting.id);
        setName(posting.name);
        setDescription(posting.description || '');
        setCategory(posting.category);
        setLocation(posting.location);
        setContact(posting.contact || '');
        setQuality(posting.quality);
        setCurrentView('edit'); 
    };

    // Handle edit posting submission
    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const updatedPosting = {
            name,
            description,
            poster_id: studentId,
            category,
            location,
            contact,
            quality, 
        };
        axios.post(`${apiUrl}/update/${editingPostingId}`, updatedPosting)
            .then(response => {
                console.log(response.data.message);
                setCurrentView('view'); // Go back to viewing postings after editing
                resetForm(); 
            })
            .catch(error => {
                console.error('Failed to update posting:', error);
            });
    };

    // Handle delete posting
    const handleDeletePosting = (postingId: number) => {
        axios.post(`${apiUrl}/postings/${postingId}`)
            .then(response => {
                console.log(response.data.message);
                setPostings(postings.filter(posting => posting.id !== postingId)); // Remove posting from UI
            })
            .catch(error => {
                console.error('Failed to delete posting:', error);
            });
    };
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        
        // Redirect to the login page
        window.location.href = '/'; 
    };
    


    // Reset form fields
    const resetForm = () => {
        setName('');
        setDescription('');
        setCategory('');
        setLocation('');
        setContact('');
        setQuality(1);
        setEditingPostingId(null);
    };

    // Categories for the dropdown
    const categories = [
        'Furniture',
        'Electronics',
        'Clothing',
        'Books',
        'Miscellaneous'
    ];

    return (
        <div>
            <button onClick={handleViewPostings}>View Postings</button>
            <button onClick={handleCreatePosting}>Create Posting</button>

            {currentView === 'view' && (
                <div>
                    <h1>Postings:</h1>
                    {postings.length > 0 ? (
                        postings.map(posting => (
                            <div key={posting.id}>
                                <h3>{posting.name}</h3>
                                <p>{posting.description}</p>
                                <p>Category: {posting.category}</p>
                                <p>Location: {posting.location}</p>
                                <p>Contact: {posting.contact}</p>
                                <p>Quality: {posting.quality}</p> {/* Display quality */}
                                <p>Time Posted: {posting.created_at}</p>
                                <p>ID: {posting.id}</p>
                                {posting.poster_id === studentId && (
                                  <>
                                    <button onClick={() => handleEditPosting(posting)}>Edit Posting</button>
                                    <button onClick={() => handleDeletePosting(posting.id)}>Delete Posting</button>
                                  </>
                                )}
                                <br /><br /> {/* Line breaks for better separation */}
                            </div>
                        ))
                    ) : (
                        <p>No postings available.</p>
                    )}
                </div>
            )}

            {(currentView === 'create' || currentView === 'edit') && (
                <div>
                    <h1>{currentView === 'create' ? 'Create Posting' : 'Edit Posting'}</h1>
                    <form onSubmit={currentView === 'create' ? handleCreateSubmit : handleEditSubmit}>
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                        <br />
                        <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        <br />
                        <select value={category} onChange={(e) => setCategory(e.target.value)} required>
                            <option value="">Select Category</option>
                            {categories.map((cat) => (
                                <option key={cat} value={cat}>{cat}</option>
                            ))}
                        </select>
                        <br />
                        <input type="text" placeholder="Location" value={location} onChange={(e) => setLocation(e.target.value)} required />
                        <br />
                        <input type="text" placeholder="Contact" value={contact} onChange={(e) => setContact(e.target.value)} />
                        <br />
                        <label>
                            Quality (1-5):
                            <input 
                                type="number" 
                                min="1" 
                                max="5" 
                                value={quality} 
                                onChange={(e) => setQuality(Number(e.target.value))} 
                                required 
                            />
                        </label>
                        <br />
                        <button type="submit">{currentView === 'create' ? 'Create Posting' : 'Update Posting'}</button>
                        
                    </form>
                </div>
            )}
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Dashboard;
