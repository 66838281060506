import axios from 'axios';
import React, { useState } from 'react';

interface LoginProps {
  onLoginSuccess: (studentId: string, studentName: string) => void; 
}

const apiUrl = process.env.REACT_APP_API_URL;


const Login: React.FC<LoginProps> = ({ onLoginSuccess }) => { 
    const [studentId, setStudentId] = useState<string>('');
    const [studentName, setStudentName] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'studentId') {
            setStudentId(value);
        } else if (name === 'studentName') {
            setStudentName(value);
        }
        if (error) setError('');
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/users`, {
                student_id: studentId,
                name: studentName,
            });
            setSuccess(response.data.message); 
            setError('');
            console.log('User added successfully:', response.data.message); 
            onLoginSuccess(studentId, studentName); 
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error('Failed to create user:', error.response ? error.response.data : error.message);
                setError(error.response ? error.response.data.error : 'Failed to create user.');
            } else {
                console.error('An unexpected error occurred:', error);
                setError('An unexpected error occurred. Please try again later.');
            }
            setSuccess('');
        }
    };

    return (
        <div className="login-container">
            <h2>Student Login</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="studentId">Student ID</label>
                    <input
                        type="text"
                        name="studentId"
                        id="studentId"
                        value={studentId}
                        onChange={handleInputChange}
                        placeholder="Enter your student ID"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="studentName">Name</label>
                    <input
                        type="text"
                        name="studentName"
                        id="studentName"
                        value={studentName}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                        required
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;
